import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Globals } from 'src/utils/globals';
import * as _ from 'lodash';

const BASE_URL = Globals.config.URL;
const COLLECTIONS = {
  products:           BASE_URL + '/products',
  products_search:    BASE_URL + '/products/search',
  products_all:       BASE_URL + '/products/search?q=',
  bestsellers:        BASE_URL + '/products/top',
  news:               BASE_URL + '/products/news',
  onsale:             BASE_URL + '/products/onsale',
  search:             BASE_URL + '/products/search',
  checkavailability:  BASE_URL + '/products/check_availability',
  productsbycategory: BASE_URL + '/categories',
  categories:         BASE_URL + '/categories',
  mercadopago:        BASE_URL + '/payments/mercadopago',
  card:               BASE_URL + '/payments/card',
  cash:               BASE_URL + '/payments/cash',
  wholesaler:         BASE_URL + '/payments/wholesaler',
  banners:            BASE_URL + '/banners',
  cities:             BASE_URL + '/cities',
  subsidiaries:       BASE_URL + '/cities/subsidiaries',
  queries:            BASE_URL + '/contact',
  installments:       BASE_URL + '/installments',
  shippingprices:     BASE_URL + '/shipping_prices',
  discount_coupons:   BASE_URL + '/discount_coupons/search_by_code',
  dolar_blue_price:   "https://api.bluelytics.com.ar/v2/latest", //response.blue.value_sell
  crypto_prices:      "https://api.cryptapi.io/:crypto/info/", //respone.prices.USD
};

@Injectable()
export class API {

  constructor(
    private httpClient: HttpClient
  ) { }

  public get(collection: string, params?: any) {
    if (!collection) return null;
    let url = COLLECTIONS[collection.toLowerCase()];

    if (params && params.categoryId) url = url + `/${params.categoryId}/products`;
    if (params && params.page) url = url + `?page=${params.page}`;
    if (params && params.search) url = url + `?q=${params.search}`;
    if (params && params.slug) url = url + `/${params.slug}`;
    if (params && params.product_slugs){
      const _params = params.product_slugs.map(ps => `product_slugs[]=${ps}`).join("&")
      url = url + `?${_params}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept':  '*/*',
        })
      };
      return this.httpClient.get(url,httpOptions);
    }
    if(params && params.coupon_code){
      url = url + `/?coupon_code=${params.coupon_code}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept':  '*/*',
        })
      };
      return this.httpClient.get(url,httpOptions);
    }
    //options btc, eth, xmr
    if (params && params.crypto) url = url.replace(":crypto", params.crypto)

    return this.httpClient.get(url);
  }

  public post(collection: string, params?: any, options?: any) {
    if (!collection) return null;

    let url = COLLECTIONS[collection.toLowerCase()];
    return this.httpClient.post(url, params, options);
  }

  public put(collection: string, params?: any) {
    if (!collection || _.isEmpty(params) || !params.id) return null;

    let url = COLLECTIONS[collection.toLowerCase()] + '/' + params.id;
    return this.httpClient.put(url, params);
  }

  public delete(collection: string, id: string) {
    if (!collection || _.isEmpty(id)) return null;

    let url = COLLECTIONS[collection.toLowerCase()] + '/' + id;
    return this.httpClient.delete(url);
  }

}
