import { Component, NgZone, HostListener } from '@angular/core';
import { SharingService } from 'src/services/sharing.service';
import { API } from 'src/services/api.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  public loading: boolean;
  public loadingMore: boolean;
  public noMoreProducts: boolean;
  public productList: any;
  public displayProductList: any;
  public banners: any;
  private page: number;
  private throttleBottomReached: Function;

  constructor(
    private sharingService: SharingService,
    private zone: NgZone,
    private api: API,
  ) {
    this.loading = true;
    this.loadingMore = false;
    this.noMoreProducts = false;
    this.banners = [];

  }
  
  ngOnInit() {
    this.zone.run(() => {
      this.loadAllProducts();
      this.sharingService.currentPage.subscribe(page => {
        this.page = page || 0;
      });
      this.sharingService.currentBanners.subscribe(banners => {
        if(banners){
          this.banners = banners.filter(b => !b["ximaro"]);
        }
      });
      //this.sharingService.currentProductList.subscribe(productList => {
      //  if (_.isEmpty(productList)) {
      //    this.loadMore();
      //    return;
      //  }
      //  this.productList = productList;
      //});

      try{
      const last_time_item_added_to_cart = localStorage.getItem("last_time_item_added_to_cart")
      if(last_time_item_added_to_cart){
        const lastTimeAdded = new Date(last_time_item_added_to_cart);
        const currentTime = new Date();
        const timeDifference = currentTime.valueOf() - lastTimeAdded.valueOf();
        const delete_every_hours = 24
        const twentyFourHoursInMilliseconds = delete_every_hours * 60 * 60 * 1000;
        //console.log("timeDifference > twentyFourHoursInMilliseconds", timeDifference > twentyFourHoursInMilliseconds)
        //console.log("timeDifference > ", timeDifference )
        //console.log("twentyFourHoursInMilliseconds > ", twentyFourHoursInMilliseconds )
        //console.log("lastTimeAdded > ", lastTimeAdded )
        if (timeDifference > twentyFourHoursInMilliseconds) {
          localStorage.removeItem('cart')
          localStorage.removeItem('last_time_item_added_to_cart')
        }
      }
    } catch (error){}
    });
  
    this.throttleBottomReached = _.throttle(() => this.loadMore(), 1 * 1000);
  }

  public showProductList() {
    return true
    if (_.isEmpty(this.productList && this.productList[0] && this.productList[0].products)) {
      console.log("SHOW FALSE")
      this.loading = true;
      return false;
    }
    else {
      console.log("SHOW TRUE")
      this.loading = false;
      return true;
    }
  }

  loadMore(){
    if(!this.loading){
      let v = this.displayProductList[0].products.length
      this.displayProductList = [{name:"", products: this.productList[0].products.slice(0,v+10)}]
    }
  }

  loadAllProducts() {
    if (this.noMoreProducts || this.loadingMore) return;

    this.page++;
    let params = { 
      //page: this.page || 1,
      search: "a", 
    };

    this.loadingMore = true;
    this.api.get('products_all').subscribe(
      (_productList: any[]) => {
        if (_.isEmpty(_productList)) {
          this.page--;
          console.log('Parece no haber más productos');
          this.noMoreProducts = true;
          return;
        }
        //let previousList = this.productList || [];
        //this.productList = previousList.concat(productList);
        let sorted_products = _productList.sort((p1,p2) =>  (new Date(p2.updatedAt)).valueOf() - (new Date(p1.updatedAt)).valueOf() ).filter(p => p.available)
        this.productList = [{name:"", products: sorted_products}]
        this.displayProductList = [{name:"", products: sorted_products.slice(0,10)}]
        this.sharingService.changeProductList(this.productList);
        this.loadingMore = false;
        this.loading = false;
      },
      error => { 
        console.log('Error', error);
        this.page--;
      },
      () => {
        this.sharingService.changePage(this.page);
        setTimeout(() => { this.loadingMore = false }, 2 * 1000)
      }
    );
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    let windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    let body = document.body, html = document.documentElement;
    let docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    let windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight - 400) {
      this.throttleBottomReached();
    }
  }
}
