import { HomeComponent } from 'src/pages/home/home.component';
import { LiquidacionComponent } from 'src/pages/liquidacion/liquidacion.component';
import { LaEmpresaComponent } from 'src/pages/la-empresa/la-empresa.component';
import { PerfilComponent } from 'src/pages/perfil/perfil.component';
import { MiProductoComponent } from 'src/pages/mi-producto/mi-producto.component';
import { ConsultasComponent } from 'src/pages/consultas/consultas.component';
import { CategoriaComponent } from 'src/pages/categoria/categoria.component';
import { SearchComponent } from 'src/pages/search/search.component';
import { CarritoComponent } from 'src/pages/carrito/carrito.component';
import { LoginComponent } from 'src/pages/login/login.component';
import { VentasMayorFormComponent } from 'src/pages/ventas-mayor-form/ventas-mayor-form.component';
import { TestimoniosComponent } from 'src/pages/testimonios/testimonios.component';
import { SuccessComponent } from 'src/pages/success/success.component';
import { FailureComponent } from 'src/pages/failure/failure.component';
import { PendingComponent } from 'src/pages/pending/pending.component';
import { NewsComponent } from 'src/pages/news/news.component';
import { MasVendidosComponent } from 'src/pages/mas-vendidos/mas-vendidos.component';

import { AuthGuardService } from 'src/services/authguard.service';

export const routes = [
	{ path: '', component: HomeComponent, canActivate : [AuthGuardService] },
	{ path: 'liquidacion', component: LiquidacionComponent, canActivate : [AuthGuardService] },
	{ path: 'empresa', component: LaEmpresaComponent, canActivate : [AuthGuardService] },
	{ path: 'perfil', component: PerfilComponent, canActivate : [AuthGuardService] },
	{ path: 'producto/:slug', component: MiProductoComponent, canActivate : [AuthGuardService] },
	{ path: 'consultas', component: ConsultasComponent, canActivate : [AuthGuardService] },
	{ path: 'categoria/:slug', component: CategoriaComponent, canActivate : [AuthGuardService] },
	{ path: 'search/:slug', component: SearchComponent, canActivate : [AuthGuardService] },
	{ path: 'carrito', component: CarritoComponent, canActivate : [AuthGuardService] },
	{ path: 'login', component: LoginComponent },
	{ path: 'ventas-por-mayor', component: VentasMayorFormComponent, canActivate : [AuthGuardService] },
	{ path: 'testimonios', component: TestimoniosComponent, canActivate : [AuthGuardService] },
	{ path: 'success', component: SuccessComponent },
	{ path: 'failure', component: FailureComponent },
	{ path: 'pending', component: PendingComponent },
	{ path: 'novedades', component: NewsComponent, canActivate : [AuthGuardService] },
	{ path: 'mas-vendidos', component: MasVendidosComponent, canActivate : [AuthGuardService] },
	{ path: 'home', redirectTo: '', pathMatch: 'full', canActivate : [AuthGuardService] },
	{ path: '**', component: HomeComponent, canActivate : [AuthGuardService] },
];