import { Component, OnInit } from '@angular/core';
import { SharingService } from 'src/services/sharing.service';
import { API } from 'src/services/api.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  public productList: any;
  public loading: boolean;

  constructor(
    private api: API,
    private sharingService: SharingService,
  ) {
    this.sharingService.currentNews.subscribe(productList => {
      if (_.isEmpty(productList)) return;
      else this.productList = productList;
    });
  }

  ngOnInit() {
    if (_.isEmpty(this.productList)) this.getProductList();
  }

  private getProductList() {
    this.loading = true;

    this.api.get('news').subscribe(
      (response: any) => {
        this.productList = response.slice(0, 20);
        this.sharingService.changeNews(this.productList);
      },
      error => { this.loading = false; console.log('Error', error) },
      () => this.loading = false
    );
  }
}
