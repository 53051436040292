export class Globals {
  public static config = {
    // STAGING
    //URL: "https://ximaro-staging.herokuapp.com/api/v1",

    // PROD
    URL: "https://api.ximaro.com.ar/api/v1",

    // DEVELOPMENT
    //URL: "http://localhost:5000/api/v1",
  }
}
