import { Component, OnInit } from '@angular/core';
import { SharingService } from 'src/services/sharing.service';
import { API } from 'src/services/api.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-liquidacion',
  templateUrl: './liquidacion.component.html',
  styleUrls: ['./liquidacion.component.scss']
})
export class LiquidacionComponent implements OnInit {
  public productList: any;
  public loading: boolean;

  constructor(
    private api: API,
    private sharingService: SharingService,
  ) {
    this.sharingService.currentOnsale.subscribe(productList => {
      if (_.isEmpty(productList)) return;
      else this.productList = productList;
    });
  }
  
  ngOnInit() {
    if (_.isEmpty(this.productList)) this.getProductList();
  }
  
  private getProductList() {
    this.loading = true;

    this.api.get('onsale').subscribe(
      (response: any) => {
        let list = response.slice(0, 32);
        list.forEach(p => p.discountPercentage = Math.round(((p.price - p.discount) * 100) / p.price));
        this.productList = _.orderBy(list, ['discountPercentage'], ['desc']);
        this.sharingService.changeOnsale(this.productList);
      },
      error => { this.loading = false; console.log('Error', error) },
      () => this.loading = false
    );
  }
}
