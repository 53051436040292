import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd, Scroll } from '@angular/router';
import { SharingService } from 'src/services/sharing.service';
import { AuthService } from 'src/services/auth.service'
import { API } from 'src/services/api.service';
import { filter } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import * as _ from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public categoryList: any;
  public productList: any;
  public filteredCategoryList: any;
  public filteredProductList: any;
  public itemsOnCart: number;
  public loadingProducts: boolean;
  public loadingCategories: boolean;
  public isAuthenticated: boolean;
  public isCategoryActive: boolean;
  public isHomeActive: boolean;
  public searchText: string;
  public navbarOpen: boolean;
  public itemsOnCartChanged: boolean;
  public isCarritoPage: boolean;
  public searching: boolean;
  private throttledSearch: Function;

  constructor(
    private router: Router,
    private sharingService: SharingService,
    public authService: AuthService,
    public api: API,
    private viewportScroller: ViewportScroller
  ) {
    this.filteredCategoryList = [];
    this.filteredProductList = [];
    this.loadingProducts = false;
    this.itemsOnCartChanged = false;
    this.isCarritoPage = false;
    this.loadingCategories = false;
    this.isCategoryActive = false;
    this.isHomeActive = false;
    this.navbarOpen = false;
    this.searching = false;

    this.sharingService.currentCategoryList.subscribe(categoryList =>
      this.categoryList = _.clone(_.orderBy(categoryList, ['name']))
    );
    this.sharingService.currentProductList.subscribe(productList =>
      this.productList = _.clone(productList)
    );
    this.sharingService.currentCart.subscribe(cart => {
      this.itemsOnCart = _.sumBy(_.map(cart, c => c.count));
      this.itemsOnCartChanged = true;
      setTimeout(() => { this.itemsOnCartChanged = false }, 200);
    });
    this.filter();
    this.throttledSearch = _.throttle(text => this.doSearch(text), 800);

    this.api.get('installments').subscribe(installments => this.sharingService.changeInstallments(installments));
    this.api.get('shippingprices').subscribe(shippingPrice => this.sharingService.changeShippingPrice(shippingPrice));
  }

  ngOnInit() {
    this.loadingProducts = true;
    this.loadingCategories = true;

    let user = JSON.parse(localStorage.getItem('ximaroUser'));
    if (user != null) {
      this.authService.user = user;
    }

    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: any) => {
      this.clearSearchText();
      this.isCarritoPage = e.url.includes('carrito');
      if (e.url.includes('categoria')) {
        this.isCategoryActive = e.url.split('/')[2] ? true : false;
      } else {
        this.isCategoryActive = false;
      }
      if (_.isEmpty(e.url.replace('/', ''))) {
        this.isHomeActive = true;
      } else {
        this.isHomeActive = false;
      } 
    });

    this.router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: any) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 450) {
      let element = document.getElementById('navbar');
      if(element){
        element.classList.add('sticky');
        document.querySelector('.icon-home').classList.add('icon-scroll');
      }
    } else {
      let element = document.getElementById('navbar');
        if(element){
          element.classList.remove('sticky'); 
          document.querySelector('.icon-home').classList.remove('icon-scroll');
        }
    }
  }

  public search(event) {
    let text = event.target.value;
    console.log(event.target.value)
    this.throttledSearch(text);
    if (event.key === 'Enter') {
      this.router.navigate([`/search/${text}`]);
    }
  }
  
  public closeMenu() {
    this.navbarOpen = false;
  }

  public showProductList() {
    if (this.productList.length > 0 && !this.loadingProducts) return true;
    else return false;
  }

  public showCategoryList() {
    if (this.categoryList.length > 0 && !this.loadingCategories) return true;
    else return false;
  }

  public toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
    this.clearSearchText();
  }

  public logout() {
    this.authService.logout();
    location.reload();
  }

  private filter() {
    if (_.isEmpty(this.searchText)) {
      this.filteredProductList = [];
      this.filteredCategoryList = [];
      return;
    }

    let categories = _.filter(this.categoryList, c => { return c.name.toLowerCase().includes(this.searchText) });
    _.each(categories, c => { c.showName = c.name.length > 30 ? c.name.substr(0, 29) + '...' : c.name})
    this.filteredCategoryList = _.slice(categories, 0, 6);
  }

  public clearSearchText() {
    this.searchText = '';
    this.filter();
  }

  private doSearch(t: string) {
    this.filter();
    if (_.isEmpty(this.searchText) || this.searchText.length <= 2) return;

    this.searching = true;
    this.api.get('search', { search: t }).subscribe(
      success => this.onSuccessSearch(success),
      error => {
        this.searching = false;
        console.log(error);
      },
      () => this.searching = false
    )
  }

  private onSuccessSearch(list: any) {
    _.each(list, p => { p.showName = p.name.length > 30 ? p.name.substr(0, 29) + '...' : p.name });
    this.filteredProductList = _.slice(list, 0, 6);
  }

  public openProduct(p: any) {
    this.toggleNavbar();
    this.sharingService.changeProduct(p);
    this.router.navigate(['/producto/' + p.slug]);
  }
}
