import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/services/auth.service'

@Injectable()
export class AuthGuardService implements CanActivate {
 
    constructor(private _router:Router, public authService: AuthService) {
    }
 
    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {
        
        //check some condition  
        if (!this.authService.isAuthenticated())  {
            //alert('You are not allowed to view this page');
            //redirect to login/home page etc
            //return false to cancel the navigation

            this._router.navigate(['/login']);
            return false;
        } 

        try{
            this.authService.checkToken().then(value => {
              console.log(value); 
            }, reason => {
              if(reason.status === 401){
                this.authService.logoutTest()
                this._router.navigate(['/login']);
                return false;
              } 
            });
        } catch (error) {}

        return true;
    }
 
}