import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { SharingService } from 'src/services/sharing.service';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { API } from 'src/services/api.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-mi-producto',
  templateUrl: './mi-producto.component.html',
  styleUrls: ['./mi-producto.component.scss']
})
export class MiProductoComponent implements OnInit {
  public galleryOptions: NgxGalleryOptions[];
  public galleryImages: NgxGalleryImage[];
  public product: any;
  public productSlug: any;
  public loadingProduct: boolean;
  public isWholesaler: boolean;
  public inCart: boolean;
  public installments: number;
  public percentage: number;
  public productShareText: boolean;
  public productURL: string;
  public copiedShareLink: boolean;

  constructor(
    private sharingService: SharingService,
    private activatedRoute: ActivatedRoute,
    private api: API,
    public meta: Meta,
  ) {
    this.product = null;
    this.productShareText = null;
    this.productURL = null;
    this.loadingProduct = false;
    this.copiedShareLink = false;
    let user = JSON.parse(localStorage.getItem('ximaroUser'));
    if (user != null) this.isWholesaler = user.kind === 'wholesaler';
    this.sharingService.currentInstallments.subscribe(response => {
      this.installments = response && response[0].installments || 12;
      this.percentage = (response && ((response[0].percentage / 100) + 1)) || 1.6;
    });     
  }
  
  ngOnInit() {
    this.activatedRoute.params.subscribe(routeParams => {
      console.log(routeParams)
      let slug = routeParams && routeParams.slug;

      if (slug) {
        console.log("slug", slug)
        this.loadingProduct = true;
        this.api.get('products', { slug }).subscribe(product => {
          this.setGalleryOpts(product['picturesImages']);
          this.updateMetaTags(product)
          console.log(product)
          this.productURL = window.location.href
          this.productShareText = product['name']
          this.product = product;
          this.loadingProduct = false;
          this.copiedShareLink = false;
        }, error => {
          console.log('Error', error);
          this.loadingProduct = false;
        });
      }
    });

    this.sharingService.currentCart.subscribe(cart => {
      let productsOnCart = _.map(cart, product => { return product.id });
      this.inCart = productsOnCart.includes(this.product && this.product.id);
    });
  }
  
  public showProduct() {
    if (this.product && !this.loadingProduct) return true;
    else return false;
  }

  public updateMetaTags(product){
    //<meta name="twitter:card" content="summary_large_image">
    //<meta name="twitter:creator" content="@edent">
    //<meta name="twitter:site" content="@edent">
    //<meta name="twitter:title" content="Tim Berners-Lee and the Freedom of The City">
    //<meta name="twitter:description" content="Last week, I had the privilege of being invited into the Guildhall to watch Tim Berners-Lee receive the Honorary Freedom of the City.  I was one of a dozen bloggers and tweeters asked to live tweet th">
    //<meta name="twitter:description" content="https://shkspr.mobi/blog/wp-content/uploads/2014/09/TimBL-Least-Well-Dressed-435x375.jpg">
    //<meta name="twitter:description" content="Last week, I had the privilege of being invited into the Guildhall to watch Tim Berners-Lee receive the Honorary Freedom of the City.  I was one of a dozen bloggers and tweeters asked to live tweet th">
    //<meta name="twitter:image:src" content="https://shkspr.mobi/blog/wp-content/uploads/2014/09/TimBL-Least-Well-Dressed-435x375.jpg">

    //<meta name="twitter:image:width" content="280">
    //<meta name="twitter:image:height" content="150">

    //this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    //twitter tags
    this.meta.updateTag({ name: 'twitter:title', content: product.name });
    this.meta.updateTag({ name: 'twitter:description', content: product.shortDescription });
    this.meta.updateTag({ name: 'twitter:image:src', content: product.picturesImages[0] });
    this.meta.updateTag({ name: 'twitter:image:width', content: '280' });
    this.meta.updateTag({ name: 'twitter:image:height', content: '150' });

    //facebook tags
    this.meta.updateTag({ name: 'og:title', content: product.name });
    this.meta.updateTag({ name: 'og:description', content: product.shortDescription });
    this.meta.updateTag({ name: 'og:image', content: product.picturesImages[0] });
    this.meta.updateTag({ name: 'og:url', content: this.productURL });
  }

  private setGalleryOpts(images) {
    this.galleryOptions = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryImages = [];

    _.each(images, i => {
      this.galleryImages.push({
        small: i, medium: i, big: i
      });
    });
  }

  public addToCart(p) {
    p.count = 1;
    let savedCart: Array<any> = JSON.parse(localStorage.getItem('cart')) || [];
    let itemsToSave: Array<any> = _.uniqBy([...savedCart, p], 'id');
    console.log(`${p.name} was added to the cart at ${(new Date()).toISOString()}!`);
    localStorage.setItem('last_time_item_added_to_cart', (new Date()).toISOString())
    this.sharingService.changeCart(itemsToSave);
  }

  public copy_to_clipboard(){
    navigator["clipboard"].writeText(this.productURL).then(function() {
      console.log("copied url")
    }, function() {
      console.log("not copied")
    });
    
    this.copiedShareLink = true;
    setTimeout(() => {
      this.copiedShareLink = false;
    }, 4000);
  }
}
