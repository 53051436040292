import { Component, OnInit, Input, NgZone } from '@angular/core';
import { SharingService } from 'src/services/sharing.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss']
})
export class ItemCardComponent implements OnInit {
  @Input() p: any;
  @Input() term: string;
  public isWholesaler: boolean;
  public inCart: boolean;
  public countInCart: number;
  public installments: number;
  public percentage: number;

  constructor(
    private sharingService: SharingService,
    private zone: NgZone,
    private router: Router,
  ) {
    this.sharingService.currentInstallments.subscribe(response => {
      this.installments = response && response[0].installments || 12;
      this.percentage = (response && ((response[0].percentage / 100) + 1)) || 1.6;
    });
  }
  
  ngOnInit() {
    this.countInCart = 0
    this.sharingService.currentCart.subscribe(cart => {
      let productsOnCart = _.map(cart, product => { return product.id });
      this.zone.run(() => { 
        this.inCart = productsOnCart.includes(this.p && this.p.id) 
        //console.log("productsOnCart", productsOnCart)
        if(this.inCart){
          let _countInCart = cart.find(p => this.p.id == p.id).count
          this.countInCart = _countInCart 
          //console.log("this.p.id", countInCart)
        }
      });
    });

    if(this.term){
      this.p.name = this.p.name.toUpperCase().replace(this.term.toUpperCase(), `<b>${this.term.toUpperCase()}</b>`)
    }

    let user = JSON.parse(localStorage.getItem('ximaroUser'));
    if (user != null) {
      this.isWholesaler = user.kind === 'wholesaler';
    }
  }

  public setAsFavorite(p) {
    let savedFavorites: Array<any> = JSON.parse(localStorage.getItem('favorites')) || [];
    let itemsToSave: Array<any> = _.uniqBy([...savedFavorites, p], 'id');
    localStorage.setItem('favorites', JSON.stringify(itemsToSave));
    console.log(`Set ${p.name} as favorite <3!`);
  }

  public addToCart(e, p) {
    if(e.target.value === "" || !e.target.value) return
    p.count = parseInt(e.target.value);
    if(p.name.includes('<b>')){
      p.name = p.name.replace('<b>', '')
      p.name = p.name.replace('</b>', '')
    }
    let savedCart: Array<any> = JSON.parse(localStorage.getItem('cart')) || [];
    let itemsToSave: Array<any> = _.uniqBy([...savedCart.filter(pr => pr.id !== p.id), p], 'id');
    console.log(`${p.name} was added to the cart at ${(new Date()).toISOString()}!`);

    localStorage.setItem('last_time_item_added_to_cart', (new Date()).toISOString())
    this.sharingService.changeCart(itemsToSave);
  }

  // public openProduct(p: any) {
  //   this.sharingService.changeProduct(p);
  //   this.router.navigate(['/producto/' + p.slug]);
  // }

  public openProduct(p: any) {
    this.sharingService.changeProduct(p);
    window.open('/producto/' + p.slug);
  }
}
