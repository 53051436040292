import { Component, OnInit } from '@angular/core';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
import { AuthService } from 'src/services/auth.service'
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import User from '../../domain/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  invalidLoginMessage: string;
  invalidLogin: boolean;
  submitted: boolean;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    var isAuthenticated = this.authService.isAuthenticated();
    if (isAuthenticated) this.router.navigate(['home']);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      password: ['', Validators.required]
    });
  }

  get f() {
    return this.form.controls;
  }

  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;

    if (socialPlatform == 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    //this.socialAuthService.signIn(socialPlatformProvider).then(
    //  (userData) => {
    //    console.log(socialPlatform + " sign in data : ", userData);
    //    alert('Excelente! Ingresaste como ' + userData.name + ' desde ' + socialPlatform);
    //  }
    //);


  }

  login() {
    this.submitted = true;
    var user = <User>this.form.value;

    this.authService.login(user)
      .then((loginResult) => {
        if (loginResult.auth) {
          this.authService.user = loginResult;
          localStorage.setItem('ximaroUser', JSON.stringify(Object.assign(loginResult, { email: user.email })));
          this.authService.user = <User>this.form.value;
          this.router.navigate(["home"]);
        } else {
          this.authService.user = null;
          this.invalidLoginMessage = "usuario o contraseña inválidos";
          this.invalidLogin = true;
        }
      })
      .catch(() => {
        this.invalidLoginMessage = "usuario o contraseña inválidos";
        this.invalidLogin = true;
        this.authService.user = null;
        localStorage.removeItem('ximaroUser');       
      });

  }
}
